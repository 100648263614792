/* eslint-disable jsx-a11y/img-redundant-alt */
import { Link } from '@remix-run/react'
import { storyblokEditable } from '@storyblok/react'
import React from 'react'
import { usePlentyMediaQuery } from '#app/hooks/use-plenty-media-query'
import { cn } from '#app/utils/misc'
import {
	type AssetStoryblok,
	type VideoHeroStoryblok,
} from '#types/component-types-sb'
import PlentyBody from '../typography/plenty-body'
import PlentyHeading from '../typography/plenty-heading'
import { Button } from '../ui/button'
const desktop = '(min-width: 820px)'

const VideoHero = ({ blok }: VideoHeroStoryblok) => {
	const [onHover, setOnHover] = React.useState<string>('video')
	const isDesktop = usePlentyMediaQuery(desktop)

	const images: { image: AssetStoryblok; text: string }[] = [
		{ image: blok.womenImage, text: blok.womenText },
		{ image: blok.menImage, text: blok.menText },
		{ image: blok.kidsImage, text: blok.kidsText },
	]

	const buttons = [
		{ text: blok.womenText, link: blok.womenLink },
		{ text: blok.menText, link: blok.menLink },
		{ text: blok.kidsText, link: blok.kidsLink },
	]

	return (
		<div {...storyblokEditable(blok)} key={blok._uid}>
			<div className="relative flex h-[387px] flex-col items-center justify-start overflow-hidden md:h-[560px] lg:h-[650px]">
				<video
					src={isDesktop ? blok.video?.filename : blok.mobileVideo?.filename}
					className={cn(
						'inset-0 -z-20 aspect-auto max-h-[387px] w-full object-cover object-top transition-all md:h-[560px] md:max-h-screen md:object-cover lg:h-[650px] lg:object-cover',
					)}
					autoPlay
					muted
					poster={'https://storage.googleapis.com/plenty-prod/0001.webp'}
					loop
					playsInline
				/>
				{images.map((image, index) => (
					<img
						key={index}
						src={image?.image.filename}
						alt={image?.image.alt}
						className={cn(
							'absolute inset-0 h-full w-full object-cover object-top transition-all md:max-h-[560px] lg:max-h-[650px]',
							onHover === image.text ? 'opacity-100' : 'opacity-0',
						)}
					/>
				))}

				<div className="absolute bottom-10 z-20 space-y-4 text-center md:bottom-14">
					<div className="text-white">
						<PlentyBody>{blok.subtitle}</PlentyBody>
						<PlentyHeading as="h4" className="text-white">
							{blok.title}
						</PlentyHeading>
					</div>
					<div className="flex flex-row items-end justify-center space-x-2">
						{buttons.map(button => (
							<Link to={button.link} key={button.text}>
								<Button
									variant="secondary"
									size="secondary"
									onMouseEnter={() => setOnHover(button.text)}
									onMouseLeave={() => setOnHover('video')}
								>
									{button.text}
								</Button>
							</Link>
						))}
					</div>
				</div>
			</div>
		</div>
	)
}

export default VideoHero
