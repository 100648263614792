import { useNavigate } from '@remix-run/react'
import { storyblokEditable } from '@storyblok/react'
import { type ButtonStoryblok } from '#types/component-types-sb'
import ButtonAndLinks from '../typography/button-and-links'
import { Button } from '../ui/button'

const ButtonBlok = ({ blok }: ButtonStoryblok) => {
	const navigate = useNavigate()
	const iconElement = blok.icon?.filename ? (
		<img
			src={blok.icon.filename}
			alt={blok.icon.alt || 'Icon'}
			className="h-6 w-6"
		/>
	) : null

	const handleButtonClick = () => {
		// Check if the link is external
		const isExternal = blok.linkToPage && /^(http|https):/.test(blok.linkToPage)
		if (isExternal) {
			// Open in a new tab for external links
			window.open(blok.linkToPage, '_blank')
		} else if (blok.linkToPage) {
			// Use navigate for internal links
			navigate(blok.linkToPage)
		}
	}

	return (
		<Button
			{...storyblokEditable(blok)}
			variant={blok.variant}
			size={blok.size}
			className="flex w-fit max-w-[800px] items-center gap-2 md:w-auto md:max-w-[400px]"
			onClick={handleButtonClick}
			fullWidth={blok.fullWidth}
		>
			{!blok.appendIcon && iconElement}
			<ButtonAndLinks as="s" body={blok.buttonText} />
			{blok.appendIcon && iconElement}
		</Button>
	)
}

export default ButtonBlok
