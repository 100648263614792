import { mdScreen } from '#app/constants/media-query-variables'
import { usePlentyMediaQuery } from '#app/hooks/use-plenty-media-query'
import useMediaQuery from '#app/hooks/useMediaQuery'
import { cn } from '#app/utils/misc'
import {
	type AssetStoryblok,
	type SocialMediaLayoutStoryblok,
} from '#types/component-types-sb'
import PlentyBody from '../typography/plenty-body'
import PlentyHeading from '../typography/plenty-heading'

const SocialMediaLayout = ({ blok }: { blok: SocialMediaLayoutStoryblok }) => {
	return (
		<div className="space-y-4">
			<div className="m-auto flex w-full max-w-6xl flex-col">
				<PlentyHeading as="h7">{blok.title}</PlentyHeading>
				<PlentyBody size="md">{blok.subtitle}</PlentyBody>
			</div>
			{blok.gridMode ? <GridMode blok={blok} /> : <SimpleLayout blok={blok} />}
		</div>
	)
}

const SimpleLayout = ({ blok }: { blok: SocialMediaLayoutStoryblok }) => {
	const isMdScreen = useMediaQuery(mdScreen)
	const desktopImages = blok.mediaContent.slice(0, 5)

	const images = isMdScreen ? desktopImages : blok.mediaContent

	return (
		<div className="grid grid-cols-3 gap-2 md:grid-cols-5">
			{images.map((socialMedia: AssetStoryblok, index: number) => {
				return socialMedia.filename.endsWith('.mov') ||
					socialMedia.filename.endsWith('.mp4') ? (
					<video
						src={socialMedia.filename}
						key={index}
						autoPlay
						muted
						loop
						playsInline
						fetchpriority={'low'}
						className="h-full w-full rounded-sm object-cover object-top md:h-40 md:w-full 2xl:h-80"
					/>
				) : (
					<img
						src={socialMedia.filename}
						key={index}
						alt={socialMedia.alt}
						fetchpriority={'low'}
						loading="lazy"
						className="h-full w-full rounded-sm object-cover object-top md:h-40 md:w-full 2xl:h-80"
					/>
				)
			})}
		</div>
	)
}

const GridMode = ({ blok }: { blok: SocialMediaLayoutStoryblok }) => {
	const isDesktop = usePlentyMediaQuery('(min-width: 820px)')
	const twoPerTwoIndexes = [4, 7]

	const images = isDesktop ? blok.mediaContent : blok.mediaContent.slice(0, 9)
	return (
		<div className="m-auto grid max-w-6xl grid-cols-3 grid-rows-[repeat(3,184px)] gap-2 md:grid-cols-6 md:gap-4 ">
			{images.map((socialMedia: AssetStoryblok, index: number) => {
				return socialMedia.filename.endsWith('.mov') ||
					socialMedia.filename.endsWith('.mp4') ? (
					<video
						src={socialMedia.filename}
						key={index}
						autoPlay
						muted
						loop
						playsInline
						fetchpriority={'low'}
						className={cn(
							'h-full w-full rounded-sm object-cover object-top md:w-full ',
							twoPerTwoIndexes.includes(index)
								? 'col-span-1 object-cover md:col-span-1 md:row-span-2 '
								: '',
						)}
					/>
				) : (
					<img
						src={socialMedia.filename}
						key={index}
						alt={socialMedia.alt}
						fetchpriority={'low'}
						loading="lazy"
						className={cn(
							'h-full w-full rounded-sm object-cover object-top md:w-full ',
							twoPerTwoIndexes.includes(index)
								? 'col-span-2 row-span-2 object-cover md:col-span-1 '
								: ' ',
						)}
					/>
				)
			})}
		</div>
	)
}
export default SocialMediaLayout
